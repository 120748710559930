import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';
import { getCookie } from '../../helpers/utils';

export const fetchPickupPoints = async (id: string | undefined): Promise<any> => {
    const token = getCookie('oya_token');
    if (id) {
        try {
            const filter = [
                {
                    f: 'route.id',
                    o: '=',
                    p: [id],
                },
            ];

            const { data } = await api.get(
                `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );

            return data?.payload;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || error?.message);
        }
    }
};
