import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';

export const fetchNetworkProvider = async (): Promise<any> => {
    try {
        const { data } = await api.get(
            `${RouteConstants.NETWORK_PROVIDER}`
        );

        return data?.payload;
    } catch (error: any) {
        throw new Error(error?.response?.data?.message || error?.message);
    }
};
