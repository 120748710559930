export default function Icon({ id, open }: any) {
  return (
    <svg
      className={`${
        id === open ? 'transition delay-150 -rotate-90' : 'transition delay-150'
      }  transition-transform`}
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M26.5599 11.9333L17.8666 20.6267C16.8399 21.6533 15.1599 21.6533 14.1333 20.6267L5.43994 11.9333'
        stroke='#B40303'
        stroke-width='3'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
