import React from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import Icon from '../AccordionIcon';
import { Option, Schedule } from '../../types';
import { FormInstance, InputNumber, Radio, Form, Space } from 'antd';
import moment from 'moment';
import Select from 'react-select';

interface TripDetailsProps {
  accordionOpen: number;
  handleOpen: (value: any) => void;
  schedule: Schedule | undefined;
  form: FormInstance;
  selectedPickup: number;
  pickups: Option[];
  setSelectedMidRoute: (value: any) => void;
  setSelectedPickup: (value: any) => void;
}

const TripDetailsComponent = ({
  accordionOpen,
  handleOpen,
  schedule,
  form,
  selectedPickup,
  setSelectedMidRoute,
  pickups,
  setSelectedPickup,
}: TripDetailsProps) => {
  return (
    <Accordion
      open={accordionOpen === 1}
      icon={<Icon id={1} open={accordionOpen} />}
      placeholder=''
    >
      <AccordionHeader onClick={() => handleOpen(1)} className='border-none'>
        <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]'>
          <h2>1</h2>
          <h2>Trip details</h2>
        </div>
      </AccordionHeader>
      <AccordionBody className='overflow-auto'>
        {accordionOpen === 1 && (
          <div className='card-container font-medium text-[16px] mt-4 mb-8 lg:mx-8'>
            <div className='flex justify-between font-semibold flex-wrap'>
              <div>
                <h1 className='tracking-tight'>
                  {schedule?.route?.from?.name} - {schedule?.route?.to?.name}
                </h1>
                <p className='py-'>
                  {moment(schedule?.departures_at).format('MMMM Do, YYYY')}
                </p>
              </div>
              <div>
                <div>
                  <p>{schedule?.branch?.name}</p>
                </div>
                <div>
                  <p>{schedule?.bus?.reg_number}</p>
                  <p>
                    {schedule?.price?.currency}{' '}
                    {Intl.NumberFormat('en-US').format(
                      Number(schedule?.price?.amount)
                    )}
                  </p>
                </div>
              </div>
            </div>

            <Form.Item
              name='minors'
              className='pt-3'
              label={
                <div className='text-sm text-oya-gray'>
                  How many minors are travelling with you?
                </div>
              }
            >
              <InputNumber min={0} />
            </Form.Item>
            <div>
              <Form.Item
                label='Where will you join the trip?'
                name='pickup'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Radio.Group
                  size='large'
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 0 && form.getFieldValue('pickup_id'))
                      form.setFieldsValue({
                        pickup_id: null,
                      });
                    setSelectedPickup(value);
                  }}
                  data-testid='buy-ticket-pickup-radio-button'
                >
                  <Space direction='vertical'>
                    <Radio className='pickup__radio' value={0}>
                      Bus Terminal
                    </Radio>
                    <Radio
                      className='pickup__radio'
                      value={1}
                      disabled={pickups.length <= 0}
                    >
                      Mid Route
                    </Radio>
                  </Space>
                </Radio.Group>
                {pickups.length <= 0 && (
                  <div className='text-oya-secondary-red'>
                    This trip does not support joining midway.
                  </div>
                )}
              </Form.Item>
              {selectedPickup === 1 && (
                <div className='w-full md:w-2/3'>
                  <Form.Item
                    colon={false}
                    label={
                      <div className='font-medium text-oya-ghana-header-h7'>
                        Your pickup point
                      </div>
                    }
                    name='pickup_id'
                  >
                    <Select
                      data-testid='buy-ticket-pickup-select'
                      placeholder='Select pickup point'
                      options={pickups}
                      onChange={(e) => setSelectedMidRoute(e)}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
        )}
      </AccordionBody>
    </Accordion>
  );
};

export default TripDetailsComponent;
