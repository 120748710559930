import React from 'react';
import { Modal, Input, Form, Divider } from 'antd';
import { FormInstance } from 'antd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CustomButton from '../CustomBtn';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import verifyImg from '../../assets/img/verify.gif';
import loadingImg from '../../assets/img/loading.gif';
import successImg from '../../assets/img/success.gif';

interface ModalContentProps {
  isBusy: boolean;
  otp_required: boolean;
  processing: boolean;
  success: boolean;
  failed: boolean;
  stilProcessing: boolean;
  countdown: number;
  form: FormInstance;
  submitOtp: (otp: string) => void;
  setOtp: (otp: string) => void;
  setIsBusy: (isBusy: boolean) => void;
  setOTPRequired: (otpRequired: boolean) => void;
  setStillProcessing: (stillProcessing: boolean) => void;
  paymentId: string;
  submitting: boolean;
  submittingOTP: boolean;
  setSuccess: (success: boolean) => void;
  setFailed: (failed: boolean) => void;
}

const ModalContent: React.FC<ModalContentProps> = ({
  isBusy,
  otp_required,
  processing,
  success,
  failed,
  stilProcessing,
  countdown,
  form,
  submitOtp,
  setOtp,
  setIsBusy,
  setOTPRequired,
  setStillProcessing,
  paymentId,
  submitting,
  submittingOTP,
  setSuccess,
  setFailed,
}) => {
  const navigate = useNavigate();
  return (
    <Modal open={isBusy} footer={null} closable={false}>
      {otp_required && (
        <div
          role='form'
          aria-label='otp-input'
          // form={form}
          // layout='vertical'
          // className='appear'
        >
          <div className='pb-4'>
            <div className='font-semibold text-[20px] text-center'>
              Verify Payment Mobile Number
            </div>
            <div>Enter OTP sent to your number</div>
          </div>
          <Form.Item
            colon={false}
            label='OTP'
            name='otp'
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <div className='flex justify-center mt-4 mb-3'>
              <img src={verifyImg} alt='verify' />
            </div>
            <Input
              size='large'
              role='textbox'
              aria-label='otp-input'
              disabled={submitting}
              placeholder='000000'
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Item>
          <div className='text-right flex items-end gap-3 justify-end'>
            {submittingOTP ? (
              <CustomButton data-testid='submitPay' disabled={submittingOTP}>
                <Loader width='w-6' height='h-6' />
              </CustomButton>
            ) : (
              <CustomButton
                data-testid='otp-pay-button'
                role='button'
                aria-label='otp-submit-btn'
                onClick={() => submitOtp(paymentId)}
              >
                Pay
              </CustomButton>
            )}
            <CustomButton
              data-testid='cancelPay'
              variant='secondary'
              onClick={() => {
                setIsBusy(false);
                setOTPRequired(false);
                navigate(-1);
              }}
            >
              Cancel
            </CustomButton>
          </div>
        </div>
      )}
      {processing && (
        <div>
          <div className='flex justify-center mt-4'>
            {/* <img src={processingImg} alt='processing' /> */}
            <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
              Processing payment
            </div>
          </div>
          <div className='text-center py-3'>
            You will receive a prompt to enter your Pin to complete this
            transaction. You will lose your selected seat if payment is not made
            before the count down.
          </div>
          <div className='text-center text-[20px] py-4'>{countdown}</div>
          <Divider className='my-4' />
          <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
            Processing...
          </div>
        </div>
      )}

      {success && (
        <div>
          <div className='w-full flex justify-end items-end'>
            <button
              data-testid='successBtn'
              onClick={() => {
                navigate(-1);
                setIsBusy(false);
                setSuccess(false);
              }}
            >
              <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
            </button>
          </div>
          <div className='flex justify-center mt-4 items-center gap-3'>
            <img src={successImg} alt='processing' />
          </div>
          <div className='text-center text-[24px] font-semibold text-seat-green py-1'>
            Success
          </div>
          <div className='flex items-center gap-1'>
            <div className='text-center'>
              Your trip has been booked successfully. You will be notified about
              your ticket.
            </div>
          </div>
        </div>
      )}
      {failed && (
        <div>
          <div className='w-full flex justify-end items-end'>
            <button
              data-testid='failedBtn'
              onClick={() => {
                navigate(-1);
                setIsBusy(false);
                setSuccess(false);
              }}
            >
              <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
            </button>
          </div>

          <div className='flex items-center gap-3'>
            <XMarkIcon className='w-12 h-12 text-red-500' />
            <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
              Payment Failed
            </div>
          </div>
          <div className='flex items-center gap-1'>
            <div className='text-center py-3 text-lg'>
              We could not process your payment. Try again later.
            </div>
          </div>
          <CustomButton
            data-testid='closePayment'
            onClick={() => {
              //setStillProcessing(false);
              setIsBusy(false);
              setFailed(false);
              navigate(-1);
            }}
          >
            Close
          </CustomButton>
        </div>
      )}
      {stilProcessing && (
        <div>
          <div className='w-full flex justify-end items-end'>
            <button
              data-testid='processBtn'
              onClick={() => {
                navigate(-1);
                setIsBusy(false);
                setSuccess(false);
              }}
            >
              <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
            </button>
          </div>
          <div className='flex justify-center mt-4 items-center gap-3'>
            <img src={loadingImg} alt='processing' />
          </div>

          <div className='flex justify-between items-center'>
            <div className='text-center py-3 text-base'>
              This is taking longer than expected. This could be as a result of
              the payment provider's prompt failing.
            </div>
          </div>

          <button
            data-testid='dismissProcess'
            className='text-oya-red-50 font-semibold hover:text-red-400'
            onClick={() => {
              //setStillProcessing(false);
              setIsBusy(false);
              setStillProcessing(false);
              navigate(-1);
            }}
          >
            Dismiss
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ModalContent;
