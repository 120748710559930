import { useState, } from 'react';
import { FormInstance, notification } from 'antd';
import { api } from '../helpers/api';
import { RouteConstants } from '../helpers/constants';
import { useParams } from 'react-router-dom';
import { getCookie } from '../helpers/utils';

const required = {
    0: ['minors', 'pickup'],
};

const useBookTrip = (form: FormInstance) => {
    const token = getCookie('oya_token');
    const params = useParams();

    const [submitting, setSubmitting] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [otp_required, setOTPRequired] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [stilProcessing, setStillProcessing] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [isCounting, setIsCounting] = useState(false);
    const [selectedPickup, setSelectedPickup] = useState(0);
    const [selectedMidRoute, setSelectedMidRoute] = useState<any>();
    const [selectedProvider, setSelectedProvider] = useState<any>();
    const [pay_phone, setPayPhone] = useState('');

    const [accordionOpen, setAccordionOpen] = useState(0);
    const [paymentId, setPaymentId] = useState('');
    const [otp, setOtp] = useState('');
    const [submittingOTP, setSubmittingOTP] = useState(false);
    const [socketUrl, setSocketUrl] = useState<string | null>(null);
    const [socketToken, setSocketToken] = useState<string | null>(null);

    const handleOpen = (value: any) => {
        setAccordionOpen(accordionOpen === value ? 0 : value);
    };

    const payForTicket = async (ticket_id: Array<number>) => {
        try {
            const res = await api.post(
                RouteConstants.PAYMENTS,
                {
                    account_provider: selectedProvider?.value,
                    account_name: form.getFieldValue('account_name'),
                    account_number: pay_phone,
                    method: selectedProvider?.value,
                    services: ticket_id,
                    type: 'DEBIT',
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setPaymentId(res.data?.payload?.id);

            if (res.data?.payload?.auth?.type === 'OTP') {
                setSocketToken(res.data?.payload?.realtime?.token);
                setSocketUrl(res.data?.payload?.realtime?.endpoint);

                setIsBusy(true);
                setOTPRequired(true);

            } else {
                notification.info({
                    message: 'Payment Initiated. Waiting for approval...',
                    duration: 15,
                });

                const socket = new WebSocket(
                    `${res.data?.payload?.realtime?.endpoint}?token=${res.data?.payload?.realtime?.token}`
                );
                setIsBusy(true);
                setProcessing(true);
                setSubmitting(false);
                setIsCounting(true);

                // Connection opened
                socket.addEventListener('open', (event) => {
                    console.log('socket connection open');
                });

                // Listen for messages
                socket.addEventListener('message', (event) => {
                    const data = JSON.parse(event.data);

                    if (data?.status?.code === 'SUCCESS') {
                        setSuccess(true);
                        setProcessing(false);
                        setIsCounting(false);
                        socket.close();
                    }

                    if (data?.status?.code === 'FAILED') {
                        setFailed(true);
                        setSuccess(false);
                        setProcessing(false);
                        setIsCounting(false);

                        socket.close();
                    }

                    //console.log('Message from server ', event.data);
                });
            }
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message || e?.message,
                duration: 15,
            });
            setSubmitting(false);
            //handleClosed();
            //navigate(-1);
        }
    };

    const createTicket = async (seatArray: Array<any>) => {
        if (seatArray.length <= 0) {
            notification.error({
                message: 'Please select a seat to continue',
                duration: 10,
            });
        } else {
            form
                .validateFields(required[0])
                .then(async (v) => {
                    console.log(v);
                    try {
                        setSubmitting(true);
                        const values = form.getFieldsValue();
                        console.log(values);

                        const payload = seatArray.map((item, index) => {
                            return {
                                seat_id: item,
                                pickup_id: selectedMidRoute
                                    ? selectedMidRoute?.value
                                    : undefined,
                                schedule_id: Number(params?.id),
                                minors: form.getFieldValue('minors') || 0,
                                occupant_type: 'ADULT',
                            };
                        });

                        const res = await api.post(
                            RouteConstants.TICKETS,
                            {
                                tickets: payload,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );

                        let tickets = res?.data?.payload?.map((item: any) => item?.id);

                        //setTicketNo(ticket_nums);
                        await payForTicket(tickets);
                    } catch (e: any) {
                        //console.log(e);
                        setSubmitting(false);
                        notification.error({
                            message: e?.response?.data?.message || e?.message,
                        });
                    }
                })
                .catch((e) => {
                    notification.error({
                        message: 'Fill required fields to continue',
                    });
                });
        }
    };

    const submitOtp = async () => {
        if (otp) {
            setSubmittingOTP(true);
            try {
                const { data } = await api.post(
                    `${RouteConstants.TRANSACTIONS}/${paymentId}/authorize`,
                    {
                        credential: otp,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                notification.success({
                    message: 'OTP Verified',
                    description: data.message,
                    duration: 15,
                });
                notification.info({
                    message: 'Payment Initiated. Waiting for approval...',
                    duration: 15,
                });

                const socket = new WebSocket(`${socketUrl}?token=${socketToken}`);
                setIsBusy(true);
                setProcessing(true);
                setSubmitting(false);
                setIsCounting(true);

                // Connection opened
                socket.addEventListener('open', (event) => {
                    console.log('socket connection open');
                });

                // Listen for messages
                socket.addEventListener('message', (event) => {
                    const data = JSON.parse(event.data);

                    if (data?.status?.code === 'SUCCESS') {
                        setSuccess(true);
                        setProcessing(false);
                        setIsCounting(false);
                        socket.close();
                    }

                    if (data?.status?.code === 'FAILED') {
                        setFailed(true);
                        setSuccess(false);
                        setProcessing(false);
                        setIsCounting(false);

                        socket.close();
                    }

                    //console.log('Message from server ', event.data);
                });
            } catch (e: any) {
                notification.error({
                    message: e?.response?.data?.message || e?.message,
                    duration: 15,
                });
                //handleClosed();
                setSubmittingOTP(false);
            }
        } else {
            notification.error({
                message: 'Please ensure all required fields are entered',
            });
        }
    };

    return {
        submitting,
        isBusy,
        otp_required,
        processing,
        success,
        failed,
        stilProcessing,
        countdown,
        isCounting,
        selectedPickup,
        selectedMidRoute,
        setProcessing,
        setSelectedMidRoute,
        setSelectedPickup,
        selectedProvider,
        pay_phone,
        handleOpen,
        accordionOpen,
        createTicket,
        submitOtp,
        setOtp,
        setIsBusy,
        setOTPRequired,
        setStillProcessing,
        setSelectedProvider,
        paymentId,
        setPayPhone,
        setSuccess,
        setFailed,
        setCountdown,
        submittingOTP,
    };
};

export default useBookTrip;