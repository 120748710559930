import React from 'react';
import { Input, Form } from 'antd';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import Icon from '../AccordionIcon';
import { Option } from '../../types';

type Props = {
  accordionOpen: number;
  handleOpen: (value: any) => void;

  networks: Option[];
  fetchingProviders: boolean;

  setSelectedProvider: (provider: Option | null) => void;
  pay_phone: string;
  setPaymentPhone: (phone: any) => void;
};
const PaymentInfo: React.FC<Props> = ({
  accordionOpen,
  handleOpen,
  pay_phone,
  setPaymentPhone,
  setSelectedProvider,
  fetchingProviders,
  networks,
}) => {
  return (
    <Accordion
      open={accordionOpen === 3}
      icon={<Icon id={3} open={accordionOpen} />}
      placeholder=''
    >
      <AccordionHeader onClick={() => handleOpen(3)} className='border-none'>
        <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
          <h2>3</h2>
          <h2>Payment info</h2>
        </div>
      </AccordionHeader>
      <AccordionBody>
        {accordionOpen === 3 && (
          <>
            <div className='rounded-md border-oya-gray-100 border p-3 mt-5 mb-6 sm:mx-8'>
              <Form.Item
                colon={false}
                label='Choose Payment Network'
                name='account_provider'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Select
                  placeholder='Your network provider'
                  onChange={(e) => {
                    setSelectedProvider(e);
                  }}
                  options={networks}
                  isSearchable
                  isClearable
                  isLoading={fetchingProviders}
                />
              </Form.Item>

              <Form.Item
                colon={false}
                label='Mobile Money Number'
                name='account_number'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <PhoneInput
                  value={pay_phone}
                  onChange={(e) => {
                    let formatted = e?.replace(/\s+/g, '');
                    setPaymentPhone(formatted);
                  }}
                  placeholder='0244123456'
                  className='phone bg-white w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                  defaultCountry='GH'
                />
              </Form.Item>

              <Form.Item
                colon={false}
                label='Mobile Money Name'
                name='account_name'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input size='large' placeholder='Full name' />
              </Form.Item>
            </div>
          </>
        )}
      </AccordionBody>
    </Accordion>
  );
};

export default PaymentInfo;
