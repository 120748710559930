import React from 'react';
import { Divider } from 'antd';
import { FormInstance } from 'antd';
import { CustomSeatType, TicketPrice, Option } from '../../types';
import CustomButton from '../CustomBtn';
import ReactGA from 'react-ga';

interface TicketBreakdownProps {
  form: FormInstance;
  seatObjArray: CustomSeatType[];
  selectedPickup: number;
  usr: any;
  selectedProvider: Option | null;
  pay_phone: string;
  priceDetails: TicketPrice | null;
  loadingPrices: boolean;
  seatArray: number[];
  createTicket: () => void;
}

const TicketBreakdown: React.FC<TicketBreakdownProps> = ({
  form,
  seatObjArray,
  selectedPickup,
  usr,
  selectedProvider,
  pay_phone,
  priceDetails,
  loadingPrices,
  seatArray,
  createTicket,
}) => {
  return (
    <div className='card-container p-3 lg:fixed lg:w-[393px]'>
      <div className='sm:text-lg mb-3 font-semibold'>Ticket Summary</div>
      <div className='my-3'>
        <div className='text-oya-red-50'>Trip details</div>
        <div className='flex justify-between items-center'>
          <div>Minor(s)</div>
          <div>
            {form.getFieldValue('minors') ? form.getFieldValue('minors') : 0}
          </div>
        </div>
        <div className='flex justify-between items-center'>
          <div>Seat Number(s)</div>
          {seatObjArray?.length > 0 ? (
            <div className='font-semibold flex gap-2 flex-wrap justify-end w-1/2 items-end text-right'>
              {seatObjArray?.map((item, index) => (
                <div key={index} className='flex'>
                  <div>{item?.number}</div>
                  <div>{index < seatObjArray?.length - 1 ? ', ' : ''}</div>
                </div>
              ))}
            </div>
          ) : (
            <div>No Seat selected</div>
          )}
        </div>
        <div className='flex justify-between items-center'>
          <div>Boarding</div>
          <div>{!selectedPickup ? 'Bus Terminal' : 'Mid-route'}</div>
        </div>
      </div>
      {/* Personal */}
      <Divider className='border-oya-gray my-2' />
      <div>
        <div className='text-oya-red-50'>Personal info</div>
        <div className='flex justify-between items-center'>
          <div>Name</div>

          <div>{usr?.name}</div>
        </div>
        <div className='flex justify-between items-center'>
          <div>Phone</div>
          <div>{usr?.phone}</div>
        </div>
        <div className='flex justify-between items-center'>
          <div>Primary Emergency Contact</div>
          <div>{usr?.ice1_phone}</div>
        </div>
      </div>
      {/* Payment */}
      <Divider className='border-oya-gray mt-2' />
      <div>
        <div className='text-oya-red-50'>Payment details</div>
        <div className='flex justify-between items-center'>
          <div>Payment provider</div>
          <div>{selectedProvider && <div>{selectedProvider?.label}</div>}</div>
        </div>
        <div className='flex justify-between items-center'>
          <div>Payment number</div>
          <div>{pay_phone && <div>{pay_phone}</div>}</div>
        </div>
        <div className='flex justify-between items-center'>
          <div>Payment name</div>
          <div>
            {form.getFieldValue('account_name') && (
              <span>{form.getFieldValue('account_name')}</span>
            )}
          </div>
        </div>
      </div>
      <Divider className='border-oya-gray my-2' />
      <div className='my-3'>
        <div className='flex justify-between items-center py-3'>
          <div>Price</div>
          {loadingPrices ? (
            <div className='animate-pulse w-16 h-4 bg-slate-200' />
          ) : (
            <>
              {priceDetails && seatArray.length ? (
                <div>
                  GHS
                  {Intl.NumberFormat('en-EN').format(
                    Number(priceDetails?.fare)
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </>
          )}
        </div>
        <div className='flex justify-between items-center mb-8'>
          {!selectedPickup ? <div>Service fee</div> : <div>Mid-route fee</div>}
          {loadingPrices ? (
            <div className='animate-pulse w-16 h-4 bg-slate-200' />
          ) : (
            <>
              {priceDetails && seatArray.length ? (
                <div>
                  GHS
                  {Intl.NumberFormat('en-EN').format(
                    Number(priceDetails?.serviceFee)
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </>
          )}
        </div>
        <div className='flex justify-between items-center py-2'>
          <div>Total</div>
          {loadingPrices ? (
            <div className='animate-pulse w-16 h-4 bg-slate-200' />
          ) : (
            <>
              {priceDetails && seatArray.length ? (
                <div>
                  GHS
                  {Intl.NumberFormat('en-EN').format(
                    Number(priceDetails?.amountPayable)
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </>
          )}
        </div>
      </div>

      <CustomButton
        data-testid='bookTrip'
        width='w-full'
        onClick={() => {
          ReactGA.event({
            category: `${process.env.NODE_ENV} Registered User Buy ticket`,
            action: 'Pay to book trip',
          });

          createTicket();
        }}
      >
        Pay to book trip
      </CustomButton>
    </div>
  );
};

export default TicketBreakdown;
