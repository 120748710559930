import React from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { CustomSeatType } from '../../types';
import Icon from '../AccordionIcon';

interface SeatSelectionProps {
  accordionOpen: number;
  handleOpen: (value: any) => void;
  busSeats: CustomSeatType[];
  handleSeatSelection: (seat: CustomSeatType, index: number) => void;
  seatArray: number[];
  isLarge: boolean;
}

const SeatSelection: React.FC<SeatSelectionProps> = ({
  accordionOpen,
  handleOpen,
  busSeats,
  handleSeatSelection,
  seatArray,
  isLarge,
}) => {
  return (
    <Accordion
      open={accordionOpen === 2}
      icon={<Icon id={2} open={accordionOpen} />}
      placeholder=''
    >
      <AccordionHeader onClick={() => handleOpen(2)} className='border-none'>
        <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]'>
          <h2>2</h2>
          <h2>Select Seat</h2>
        </div>
      </AccordionHeader>
      <AccordionBody>
        {accordionOpen === 2 && (
          <div className='card-container mb-3 lg:mx-8'>
            <div className=''>
              <div>
                {!isLarge && (
                  <div className='mb-4'>
                    <div className='flex gap-5 items-center mb-3'>
                      <div className='w-5 h-5 bg-seat-green' />
                      <div>Available</div>
                    </div>
                    <div className='flex gap-2 items-center'>
                      <div className='w-5 h-5 bg-oya-red-100' />
                      <div>Unavailable</div>
                    </div>
                  </div>
                )}

                <>
                  <div className='sm:flex gap-5'>
                    <div className='grid grid-cols-4 '>
                      {busSeats.map((seat, index) => (
                        <button
                          key={seat?.id}
                          //value={seat}

                          aria-label={`seat-btn-${index}`}
                          className={`pt-2 m-2 ${
                            seat?.selected ? 'bg-oya-red-20' : ''
                          }`}
                          disabled={seat?.status === 1}
                          onClick={(e) => {
                            handleSeatSelection(seat, index);
                          }}
                          data-testid='seat-element'
                        >
                          <div className=' relative flex items-center justify-center'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className={`${
                                seat?.status === 1
                                  ? 'text-oya-red-100'
                                  : 'text-seat-green'
                              }`}
                              aria-label={`seat-icon-${index}`}
                              width='44'
                              height='39'
                              viewBox='0 0 54 49'
                              fill='none'
                              role={`img`}
                            >
                              <rect
                                y='6.23016'
                                width='5.25'
                                height='37.1907'
                                rx='2.625'
                                className='fill-current'
                                fill='none'
                              />
                              <rect
                                x='48.75'
                                y='6.23016'
                                width='5.25'
                                height='37.1907'
                                rx='2.625'
                                className='fill-current'
                                fill='none'
                              />
                              <path
                                d='M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z'
                                className='fill-current'
                                fill='none'
                              />
                              <path
                                d='M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z'
                                className='fill-current'
                                fill='none'
                              />
                            </svg>

                            <span className='text-white text-oya-ghana-header-h7 absolute text-center pb-2'>
                              {seat?.number}
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                    {isLarge && (
                      <div>
                        <div className='flex gap-5 items-center mb-3'>
                          <div className='w-5 h-5 bg-seat-green' />
                          <div>Available</div>
                        </div>
                        <div className='flex gap-2 items-center'>
                          <div className='w-5 h-5 bg-oya-red-100' />
                          <div>Unavailable</div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
      </AccordionBody>
    </Accordion>
  );
};

export default SeatSelection;
