import { useEffect, Fragment, useState } from 'react';
import PageLayout from '../components/utils/PageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { Spin, Form, Divider } from 'antd';
import Loader from '../components/Loader';
import TripDetails from '../mobile/home/TripDetails';
import { useSelector } from 'react-redux';
import { UserState } from '../redux/reducer';
import useBookTrip from '../hooks/useBookTrip';
import SeatSelection from '../components/Schedules/SeatSelection';
import TripDetailsComponent from '../components/Schedules/TripDetailsComponent';
import PaymentInfo from '../components/Schedules/PaymentInfo';
import ModalContent from '../components/Schedules/ModalContent';
import ReactGA from 'react-ga';
import { useQuery } from '@tanstack/react-query';
import { fetchPickupPoints } from '../api/buyTicket/fetchPickupPoints';
import { fetchScheduleDetails } from '../api/buyTicket/fetchScheduleDetails';
import { fetchScheduleSeats } from '../api/buyTicket/fetchScheduleSeats';
import {
  CustomSeatType,
  MidRoute,
  NetworkProvider,
  Option,
  Schedule,
  TicketPrice,
} from '../types';
import { getCookie } from '../helpers/utils';
import { fetchNetworkProvider } from '../api/buyTicket/fetchNetworkProvider';
import { RouteConstants } from '../helpers/constants';
import { api } from '../helpers/api';
import TicketBreakdown from '../components/Schedules/TicketBreakdown';

const BookTrip = () => {
  const user_info = useSelector((state: UserState) => state);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLarge = useMediaQuery('(min-width:1024px)');
  const params = useParams();
  const token = getCookie('oya_token');
  const user: any = getCookie('oya_user_info');
  const usr = user ? JSON.parse(user) : {};

  const [pickups, setPickups] = useState([]);
  const [busSeats, setBusSeats] = useState<CustomSeatType[]>([]);
  const [seatArray, setSeatArray] = useState<Array<number>>([]);
  const [seatObjArray, setSeatObjArray] = useState<CustomSeatType[]>([]);
  const [networks, setNetworks] = useState<Option[]>([]);
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [priceDetails, setPriceDetails] = useState<TicketPrice | null>(null);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const scheduleDetails = useQuery({
    queryKey: [`schedule-details-${params?.id}`],
    queryFn: () => fetchScheduleDetails(params?.id),
  });

  const seatList = useQuery({
    queryKey: [`seat-list-${params?.id}`],
    queryFn: () => fetchScheduleSeats(params?.id),
  });

  const networkProviderList = useQuery({
    queryKey: ['network-provider-list'],
    queryFn: () => fetchNetworkProvider(),
  });

  const routeId = scheduleDetails.data?.route?.id;

  const pickupList = useQuery({
    queryKey: [`pickup-list-${routeId}`, routeId],
    queryFn: () => fetchPickupPoints(routeId),
    enabled: !!routeId,
  });

  const getTicketPrice = async () => {
    if (seatArray.length > 0) {
      setLoadingPrices(true);

      try {
        let payload: any = {
          schedule_id: schedule?.id,
          num_of_seats: seatArray.length,
        };
        if (selectedMidRoute) {
          payload.pickup_id = selectedMidRoute?.value;
        }
        const { data } = await api.post(
          RouteConstants.GET_TICKET_PRICE,
          { ...payload },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPriceDetails(data?.payload);
        setLoadingPrices(false);
      } catch (error) {
        setLoadingPrices(false);

        console.log(error);
      }
    }
  };

  const {
    submitting,
    isBusy,
    otp_required,
    processing,
    success,
    failed,
    stilProcessing,
    countdown,
    selectedPickup,
    selectedMidRoute,
    setSelectedMidRoute,
    setSelectedPickup,
    setSelectedProvider,
    setPayPhone,
    selectedProvider,
    pay_phone,
    handleOpen,
    accordionOpen,
    createTicket,
    submitOtp,
    setOtp,
    setIsBusy,
    setOTPRequired,
    setStillProcessing,
    paymentId,
    setSuccess,
    setProcessing,
    setFailed,
    isCounting,
    setCountdown,
    submittingOTP,
  } = useBookTrip(form);

  const handleSeatSelection = (seat: CustomSeatType, index: number) => {
    let newSeats = [...busSeats];

    const getSelectedSeat = newSeats.find((value) => value?.id === seat?.id);
    if (getSelectedSeat) {
      if (getSelectedSeat.selected === true) {
        newSeats[index].selected = false;
      } else {
        newSeats[index].selected = true;
      }
    }

    const findOne = seatArray.find((value) => value === seat?.id);

    const findSeatObj = seatObjArray.find((value) => value?.id === seat?.id);

    if (findSeatObj) {
      setSeatObjArray(
        seatObjArray.filter((selectedSeat) => selectedSeat?.id !== seat?.id)
      );
    } else {
      setSeatObjArray([...seatObjArray, seat]);
    }

    if (findOne) {
      setSeatArray(
        seatArray.filter((selectedSeat) => selectedSeat !== seat?.id)
      );
    } else {
      setSeatArray([...seatArray, seat?.id]);
    }
  };

  useEffect(() => {
    if (pickupList.isSuccess) {
      setPickups(pickupList.data);

      setPickups(
        pickupList.data?.items?.map((item: MidRoute) => ({
          label: item?.pickup?.name,
          value: item?.pickup?.id,
        }))
      );
    }

    if (seatList.isSuccess) {
      setBusSeats(seatList.data);
    }

    if (networkProviderList.isSuccess) {
      const res = networkProviderList.data?.items?.map(
        (item: NetworkProvider, index: number) => ({
          label: item?.name,
          value: item?.code,
        })
      );
      setNetworks(res);

      if (token) {
        if (
          user_info.user?.payment_account_number &&
          user_info.user?.payment_account_provider
        ) {
          form.setFieldValue(
            'account_number',
            user_info.user?.payment_account_number
          );
          form.setFieldValue('account_name', user_info.user?.name);

          const findProvider = res?.find(
            (item: any) =>
              item?.value === user_info.user?.payment_account_provider
          );
          setSelectedProvider(findProvider);
          form.setFieldValue('account_provider', findProvider);
          setPayPhone(user_info.user?.payment_account_number);
        }
      }
    }

    if (scheduleDetails.isSuccess) {
      setSchedule(scheduleDetails.data);
    }

    // eslint-disable-next-line
  }, [
    pickupList.isSuccess,
    seatList.isSuccess,
    networkProviderList.isSuccess,
    scheduleDetails.isSuccess,
  ]);

  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setFailed(true);
      setProcessing(false);
      setSuccess(false);
    }

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, [isCounting, countdown]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(() => {
    getTicketPrice();

    // eslint-disable-next-line
  }, [schedule, selectedMidRoute, seatArray]);

  return (
    <Fragment>
      {isMobile ? (
        <TripDetails />
      ) : (
        <PageLayout>
          <div className='pt-[4rem] px-[1rem] sm:px-[8rem] bg-white min-h-screen'>
            <Spin
              spinning={seatList.isLoading || submitting}
              className='py-10 md:py-20 min-h-[50vh] flex justify-center items-center'
              indicator={
                <Loader
                  float
                  bgColor='transparent appear'
                  width='w-16'
                  height='h-16'
                />
              }
            >
              {isBusy && (
                <ModalContent
                  isBusy={isBusy}
                  otp_required={otp_required}
                  processing={processing}
                  success={success}
                  failed={failed}
                  stilProcessing={stilProcessing}
                  countdown={countdown}
                  form={form}
                  submitOtp={submitOtp}
                  setOtp={setOtp}
                  setIsBusy={setIsBusy}
                  setOTPRequired={setOTPRequired}
                  setStillProcessing={setStillProcessing}
                  paymentId={paymentId}
                  submitting={submitting}
                  submittingOTP={submittingOTP}
                  setSuccess={(value) => {
                    setSuccess(value);
                  }}
                  setFailed={(value) => {
                    setFailed(value);
                  }}
                />
              )}
              {token && user ? (
                <div>
                  <button
                    data-testid='goBack'
                    className='mt-[2rem] mb-5 flex gap-3 items-center'
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIos
                      sx={{
                        width: '20px',
                        height: '20px',
                        color: '#B40303',
                      }}
                    />
                    <div className='text-oya-red-100 font-semibold text-lg'>
                      Back
                    </div>
                  </button>

                  <Form
                    layout='vertical'
                    form={form}
                    initialValues={{ minors: 0 }}
                  >
                    <div className='lg:flex flex-wrap gap-6'>
                      <div className='flex-[0.6] '>
                        <TripDetailsComponent
                          accordionOpen={accordionOpen}
                          handleOpen={handleOpen}
                          schedule={schedule}
                          form={form}
                          selectedPickup={selectedPickup}
                          pickups={pickups}
                          setSelectedMidRoute={setSelectedMidRoute}
                          setSelectedPickup={setSelectedPickup}
                        />

                        <Divider className='border-oya-gray mt-2' />

                        <SeatSelection
                          accordionOpen={accordionOpen}
                          handleOpen={handleOpen}
                          busSeats={busSeats}
                          handleSeatSelection={handleSeatSelection}
                          seatArray={seatArray}
                          isLarge={isLarge}
                        />

                        <Divider className='border-oya-gray mt-2' />

                        <PaymentInfo
                          accordionOpen={accordionOpen}
                          handleOpen={handleOpen}
                          networks={networks}
                          fetchingProviders={networkProviderList.isLoading}
                          setSelectedProvider={setSelectedProvider}
                          pay_phone={pay_phone}
                          setPaymentPhone={setPayPhone}
                        />
                      </div>
                      <div className=' flex-[0.4] '>
                        <TicketBreakdown
                          form={form}
                          seatObjArray={seatObjArray}
                          selectedPickup={selectedPickup}
                          usr={usr}
                          selectedProvider={selectedProvider}
                          pay_phone={pay_phone}
                          priceDetails={priceDetails}
                          loadingPrices={loadingPrices}
                          seatArray={seatArray}
                          createTicket={() => createTicket(seatArray)}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div>Schedule Not Available</div>
              )}
            </Spin>
          </div>
        </PageLayout>
      )}
    </Fragment>
  );
};

export default BookTrip;
