import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';
import { getCookie } from '../../helpers/utils';

export const fetchScheduleDetails = async (tripId: string | undefined): Promise<any> => {
    const token = getCookie('oya_token');
    if (tripId) {
        try {
            const { data } = await api.get(`${RouteConstants.SCHEDULES}/${tripId}/details`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return data?.payload;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || error?.message);
        }
    }
};
