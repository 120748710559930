import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';
import { getCookie } from '../../helpers/utils';

export const fetchScheduleSeats = async (id: string | undefined): Promise<any> => {
    const token = getCookie('oya_token');
    if (id) {
        try {
            const { data } = await api.get(
                `${RouteConstants.SCHEDULES}/${id}/seats`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return data?.payload?.items;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || error?.message);
        }
    }
};
